import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useRef, useState } from 'react';
import { Pressable, StyleSheet, Text } from 'react-native';
import { useGetAllBrands } from '../api/brands/useGetAllBrands';
import useCreateStarterKit from '../api/product/useCreateStarterKit';
import useEditProduct from '../api/product/useEditProduct';
import useGetAllProducts from '../api/product/useGetAllProducts';
import useGetDiscountedProducts from '../api/product/useGetDiscountedProduct';
import useGetAllStarterKits from '../api/starterKits/useGetAllStarterKits';
import {
	CreateStarterKitModalContent,
	ProductModalContent,
	StartedKitsList,
	Tabs,
} from '../components';
import Loader from '../components/common/loader/Loader';
import LoadMore from '../components/common/loadMore';
import ModalComponent from '../components/common/modal/ModalComponent';
import TableComponent from '../components/common/table-component/TableComponent';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { palettes } from '../config';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import {
	setAddProductModalState,
	setRefetchProduct,
} from '../redux/productsSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { Product, StarterKit } from '../types/productTypes';
import { PickerValue, TTableHeader } from '../types/types';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { formateDateString, manipulateString } from '../utils/manipulateString';

const TABS = ['All Products', 'Active', 'In-Active', 'Discount', 'Starter Kit'];
const Headers: TTableHeader[] = [
	{ width: 250, headerLabel: 'Products' },
	{ width: 250, headerLabel: 'Product Category' },
	{ width: 200, headerLabel: 'Inventory On' },
	{ width: 150, headerLabel: 'QTY' },
	{ width: 150, headerLabel: 'Base Total' },
	// { width: 170, headerLabel: 'Purchased Total' },
	{ width: 180, headerLabel: 'Status' },
	{ width: 100, headerLabel: '' },
];

const checkIfAnyProductIsSelected = (items: any[][]) => {
	return items
		.map(innerArray =>
			innerArray.some(
				(item, i) => innerArray[innerArray.length - 1] === true
			)
		)
		.some(item => item === true);
};

const ProductsScreen = (props: any) => {
	const dispatch = useAppDispatch();
	const { INTEREST_TAGS } = useAppSelector(state => state.tag)
	const { addProductModelState } = useAppSelector(state => ({
		addProductModelState: state.products.addProductModalState,
	}));

	const { loggedInUserDetails } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
	}));

	const [showStarterKitButton, setShowStarterKitButton] = useState(false);
	const [selectAllCheckboxState, setSelectAllCheckboxState] = useState(false);
	const [showCreateCollectionPopup, setshowCreateCollectionPopup] =
		useState(false);
	const [selectedTab, setSelectedTab] = useState(TABS[0]);
	const [tableData, setTableData] = useState([
		[
			'https://via.placeholder.com/500',
			'Order work flow management',
			'28/02/22',
			'x255',
			'$200.56',
			'$900',
			'private',
			false,
		],
		[
			'https://via.placeholder.com/500',
			'Order work flow management2',
			'28/02/22',
			'x255',
			'$200.56',
			'$900',
			'private',
			false,
		],
	]);
	const [total, setTotal] = useState(0)
	const [allProducts, setAllProducts] = useState([])
	const {
		executeAllProductQuery,
		data: productData,
		loading,
		onPressLoadMore,
		networkStatus
	} = useGetAllProducts({ setTotal });

	const {
		executeDiscountProductQuery,
		data: discountProductData,
		loading: discountProductLoading,
	} = useGetDiscountedProducts();

	const [product, setProduct] = useState<Product[]>([]);
	const [dataDetail, setDataDetail] = useState<Product[]>([]);

	const { products } = useAppSelector(states => states);

	const {
		executeProductEditMutation,
		data: productEdit,
		loading: productEditLoading,
	} = useEditProduct();

	useEffect(() => {
		if (products.refetchProduct || productEdit?.editProduct?.success) {
			if (loggedInUserDetails?.affiliatedBrand) {
				executeAllProductQuery({
					brand: loggedInUserDetails?.affiliatedBrand
				});
			} else {
				executeAllProductQuery({});
			}
			dispatch(setRefetchProduct(false));
		}
	}, [products, productEdit]);

	useEffect(() => {
		if (selectedTab === TABS[0]) {
			if (loggedInUserDetails?.affiliatedBrand) {
				executeAllProductQuery({
					brand: loggedInUserDetails?.affiliatedBrand
				});
			} else {
				executeAllProductQuery({});
			}
		}
		if (selectedTab === TABS[1]) {
			if (loggedInUserDetails?.affiliatedBrand) {
				executeAllProductQuery({
					brand: loggedInUserDetails?.affiliatedBrand,
					publishedStatus: 'ACTIVE'
				});
			} else {
				executeAllProductQuery({ publishedStatus: 'ACTIVE' });
			}
		}

		if (selectedTab === TABS[2]) {
			if (loggedInUserDetails?.affiliatedBrand) {
				executeAllProductQuery({
					brand: loggedInUserDetails?.affiliatedBrand,
					publishedStatus: 'DRAFT'
				});
			} else {
				executeAllProductQuery({ publishedStatus: 'DRAFT' });
			}
		}

		if (selectedTab === TABS[3]) executeDiscountProductQuery();
	}, [selectedTab]);

	useEffect(() => {
		if (
			productData?.getAllProducts?.success ||
			discountProductData?.getAllDiscountedProducts?.success
		) {
			// console.log({ discountProductData });
			// const objToArray = productData?.getAllProducts?.data?.data.map(
			// 	(product: Product) => {
			// 		return {
			// 			...product,
			// 			new: product.variants[0],
			// 		};
			// 	}
			// );
			const products =
				selectedTab === TABS[3]
					? discountProductData?.getAllDiscountedProducts?.data?.data
					: productData?.getAllProducts?.data?.data;
			const objToArray = products?.map((product: Product) => {
				return [
					product?.variants[product?.defaultVariant]?.image?.src,
					product.name,
					product.category
						.map(
							item =>
								INTEREST_TAGS.find(tag => tag.value === item)
									?.label ??
								manipulateString.titleCase(
									item.replace(/-/g, ' ')
								)
						)
						.join(','),
					formateDateString(product?.createdAt),
					product?.variants[product?.defaultVariant]?.option
						?.inventory?.quantity,
					`${getSymbolFromCurrency('eur')} ${product?.variants[product?.defaultVariant ?? 0]?.option
						?.values[0]?.price
					}`,
					product.publishedStatus,
					product._id,
				];
			});
			setProduct(objToArray);
			setDataDetail(products);
			setAllProducts([...allProducts, ...objToArray])
		}
	}, [productData, discountProductData, selectedTab]);

	/**
	 * API FOR CREATING STARTER KIT START
	 *
	 */
	const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);

	const [starterKitFormDet, setStarterKitFormDet] = useState({});

	const { executeStarterKitMutation, loading: starterKitCreateLoading } =
		useCreateStarterKit();

	const { getAllBrands, data: brandData } = useGetAllBrands();

	const handleStarterKitCreate = (values: any) => {
		executeStarterKitMutation({
			variants: values.variants,
			vat: +values.vat,
			brand: values.brand,
			compareAtPrice: +values.compareAtPrice,
			starterKitId: values.starterKitId,
			price: +values.price,
			description: values.description,
			name: values.name,
		});
		setshowCreateCollectionPopup(false);
	};

	/**
	 * API FOR CREATING STARTER KIT END
	 *
	 */

	/**
	 * API FOR GETTING STARTER KIT STARTS
	 *
	 */

	const {
		executeAllStarterKitsQuery,
		data: starterKitsData,
		error: starterKitsError,
	} = useGetAllStarterKits();

	useEffect(() => {
		executeAllStarterKitsQuery();
	}, [showCreateCollectionPopup]);

	const starterKits: StarterKit[] =
		starterKitsData?.getAllStarterKits?.data?.data;

	// TODO: refactor
	const onChangeCheckbox = (id: number) => {
		const product = dataDetail.find((item, index) => index === id);
		const idIndex = selectedProductIds.indexOf(product?._id as string);
		if (idIndex !== -1) {
			const newIds = [...selectedProductIds];
			newIds.splice(idIndex, 1);
			setSelectedProductIds(newIds);
		} else {
			setSelectedProductIds([
				...selectedProductIds,
				product?._id as string,
			]);
		}

		const updatedTableData: any = tableData.map((tableItem, i) => {
			if (id === i) {
				return tableItem.map((rowItem, index) => {
					const lastItemInNestedArray =
						index === tableItem.length - 1;

					if (lastItemInNestedArray) {
						return !rowItem;
					} else {
						return rowItem;
					}
				});
			} else {
				return tableItem;
			}
		});

		setTableData(updatedTableData);
	};

	useEffect(() => {
		// checkIfAnyProductIsSelected(tableData)
		if (selectedTab === TABS[4]) {
			setShowStarterKitButton(true);
		} else {
			setShowStarterKitButton(false);
		}
	}, [selectedTab]);

	const onPressStarterKitButton = () => {
		setshowCreateCollectionPopup(true);
	};

	const onPressHeaderCheckbox = () => {
		setSelectAllCheckboxState(prev => !prev);
		const tableDataWithAllCheckboxesTrue = tableData.map(
			(innerArray, _) => {
				return innerArray.map((innerArrayItem, innerArrayIndex) => {
					if (innerArrayIndex === innerArray.length - 1) {
						return !selectAllCheckboxState;
					} else {
						return innerArrayItem;
					}
				});
			}
		);
		setTableData(tableDataWithAllCheckboxesTrue);
	};

	const onChangePickerVal = (publishedStatus: PickerValue, id: number) => {
		const product = dataDetail.find(
			(item, index) => index === id
		) as Product;
		const editedProduct = {
			name: product?.name,
			description: product?.description,
			shortDescription: product?.shortDescription,
			brand: product?.brand?._id,
			category: product?.category,
			publishedStatus,
			variants: product?.variants?.map(variant => {
				return {
					variator: variant.variator,
					value: variant.value,
					GTIN: variant?.GTIN,
					image: variant?.image?._id?.length
						? variant?.image?._id
						: undefined,
					_id:
						variant?._id && variant?._id.length
							? variant?._id
							: undefined,
					vat: +variant?.vat,
					weight: Number(variant?.weight),
					weightUnit: variant.weightUnit,
					option: {
						name: variant?.option?.name,
						values: variant?.option?.values?.map(option => {
							return {
								name: option?.name,
								price: option?.price,
								compareAtPrice: option?.compareAtPrice,
							};
						}),
						inventory: {
							isAvailable: variant?.option?.inventory.isAvailable,
							isTracked: variant?.option?.inventory?.isTracked,
							quantity: Number(
								variant?.option?.inventory.quantity
							),
						},
					},
				};
			}),
		};
		console.log(editedProduct);
		executeProductEditMutation(product?._id as string, editedProduct);
	};

	const getTabs = () => {
		if (loggedInUserDetails?.affiliatedBrand) {
			return TABS?.slice(0, 3)
		} else {
			return TABS
		}
	}

	return (
		<DesktopTemplate
			navigationBarProps={{
				hasAddNewProduct: true,
			}}
		>
			<Tabs
				tabs={getTabs()}
				selectedTab={selectedTab}
				onPress={tab => {
					setSelectedTab(tab);
				}}
				showDropdowns
				actionButtonProps={
					showStarterKitButton
						? {
							label: 'Create Starter Kit',
							onPress: onPressStarterKitButton,
						}
						: undefined
				}
				checkboxProps={
					false
						? {
							isChecked: selectAllCheckboxState,
							onChange: onPressHeaderCheckbox,
						}
						: undefined
				}
			/>
			{selectedTab !== TABS[4] && !(loading && networkStatus === 1) && (
				<TableComponent
					type="product"
					data={allProducts}
					dataDetail={dataDetail}
					showHeader={false}
					selectedTab={selectedTab}
					headers={Headers}
					tableContentProps={{
						hasCheckbox: false,
						viewButton: true,
						followButton: false,
						messageButton: false,
						profileButton: false,
						onChangeCheckbox,
						onChangePickerVal,
					}}
				/>
			)}
			{((loading && networkStatus === 1) || discountProductLoading) && (
				<Loader
					size={getResponsiveStyle(16, 'dimensions')}
				// containerStyles={commonStyles.loaderContainer}
				/>
			)}
			{selectedTab === TABS[4] &&
				!!starterKits?.length &&
				starterKits.map(starterKit => (
					<StartedKitsList {...starterKit} />
				))}

			{total > allProducts.length && (
				<LoadMore
					loading={
						networkStatus === 2
					}
					onClick={onPressLoadMore}
					containerStyles={
						{ marginTop: '30px' }
					}
				/>
			)}

			<ModalComponent
				header="Add New Product"
				openState={addProductModelState}
				onClose={() => dispatch(setAddProductModalState(false))}
			>
				<ProductModalContent />
			</ModalComponent>

			<ModalComponent
				header="Create Starter Kit"
				isScrollable
				openState={showCreateCollectionPopup}
				onClose={() => {
					setshowCreateCollectionPopup(false);
				}}
			>
				<CreateStarterKitModalContent
					onSubmit={handleStarterKitCreate}
					starterKitCreateLoading={starterKitCreateLoading}
				/>
			</ModalComponent>
		</DesktopTemplate>
	);
};
export default ProductsScreen;
