import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { GET_ALL_PRODUCTS } from '../../graphql/product/queries';
import { Product, TGetAllProductFind, TGetAllProductsResponse } from '../../types/productTypes';
import { TDropdownObject } from '../../types/types'

type Params = {
	onCompletedGetAllProducts?: (data: TGetAllProductsResponse) => void
	setTotal?: React.Dispatch<React.SetStateAction<number>>
	allProducts?: [],
	setAllProducts?: React.Dispatch<React.SetStateAction<never[]>>
}
const useGetAllProducts = (params?: Params) => {
	const [getAllProducts, { data, error, loading, refetch: refetchAllProducts, networkStatus }] =
		useLazyQuery<TGetAllProductsResponse>(GET_ALL_PRODUCTS, {
			fetchPolicy: 'cache-and-network',
			notifyOnNetworkStatusChange: true,
		});

	const LIMIT = 10
	const [current, setCurrent] = useState(1)
	const executeAllProductQuery = (find: TGetAllProductFind) => {
		getAllProducts({
			variables: {
				find,
				populate: ['images'],
				limit: LIMIT
			},
			onCompleted: (data) => {
				params?.setTotal && params.setTotal(data?.getAllProducts?.data?.total)
				params?.onCompletedGetAllProducts && params.onCompletedGetAllProducts(data)
				// const productsArray: any = []
				// const products = data?.getAllProducts?.data?.data;
				// products?.map((product: Product) => {
				// 	productsArray.push({
				// 		label: product?.name,
				// 		value: product?._id,
				// 	});
				// });
				// 	params?.setProductsDropDown?.(productsArray)
				// 	params?.setBrandProducts?.(productsArray)


			}
		});
	};

	const onPressLoadMore = () => {
		setCurrent(prev => prev + 1)
		refetchAllProducts({
			limit: LIMIT,
			current: current,
		});
	}
	return { executeAllProductQuery, data, error, loading, onPressLoadMore, networkStatus };
};

export default useGetAllProducts;
